<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <div id="container">
        <ion-card class="ion-margin-top">
          <ion-card-content>
            <ion-item>
              <ion-label><b>Nombre archivo:</b> {{ fileName }} <br> <b>Origen:</b> {{ selectedOrigen.nombre }}
                <br></ion-label>
            </ion-item>
            <ion-item>
              <ion-label class="ion-text-center">
                <h2>Los datos ingresados no son correctos o están incompletos</h2>
              </ion-label>
            </ion-item>
            <ion-row class="ion-justify-content-end ion-margin-top">
              <ion-button fill="clear" size="small" color="tertiary"
                style="margin-inline: 0px; margin-top: 0px; margin-bottom: 0px; font-size: 11.5px;"
                @click="downloadErrores">DESCARGAR ERRORES</ion-button>
            </ion-row>
          </ion-card-content>
        </ion-card>
        <ion-card v-if="(Object.keys(equivalencias).length !== 0)">
          <ion-card-header>EQUIVALENCIAS FALTANTES</ion-card-header>
          <ion-card-content>
            <ion-item>
              <ion-label position='stacked' style="display: flex; justify-content: center;">
                <h1 style="font-weight: bold;">Tipo de dato</h1>
              </ion-label>
            </ion-item>
            <div style="display: flex; justify-content: center;">
              <ion-list>
                <ion-item v-for="(error, index) in progress" :key="index" @click="selectModel(error)">
                  <div style="display: flex; justify-content: space-between; align-items: center; cursor: pointer;">
                    <ion-label style="width: 250px;">{{ error.modelShow }}</ion-label>
                    <ion-button :color="colorEquivalencia(error)">{{ error.cantidad }}</ion-button>
                  </div>
                </ion-item>
              </ion-list>
            </div>
          </ion-card-content>
        </ion-card>
        <ion-card v-if="activeCardEquiv" id="card-equivalencias">
          <ion-card-content>
            <ion-grid>
              <ion-row>
                <ion-col style="font-weight: bold;">De importacion</ion-col>
                <ion-col style="font-weight: bold;">De sistema</ion-col>
                <ion-col style="font-weight: bold;">Acción</ion-col>
              </ion-row>
              <ion-row class="ion-align-items-center"
                v-for="(error, index) in  equivalenciasFiltered(selectedError.seccion, selectedError.model)" :key="index">
                <ion-col> <ion-label>{{ error.value }}</ion-label></ion-col>
                <ion-col v-if="error.valueEquivalencia" style="cursor: pointer;">
                  <ion-item style="cursor: pointer;">
                    <ion-input :value="(error.valueEquivalencia)" class="hovered" color='success'
                      @click="activateModalSelecEquiv(error)"></ion-input>
                    <ion-label></ion-label>
                  </ion-item>
                </ion-col>
                <ion-col v-else class="hovered" style="cursor: pointer;">
                  <ion-input value="Click para seleccionar" style="color: #a27b00; cursor: pointer !important;"
                    @click="activateModalSelecEquiv(error)"></ion-input>
                </ion-col>
                <ion-col v-if="error.valueEquivalencia">
                  <ion-button size="small" color="medium" @click="deleteEquiv(error)">
                    <ion-icon slot="icon-only" :icon="icons.trash"></ion-icon>
                  </ion-button>
                </ion-col>
                <ion-col v-else></ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </ion-card>
        <ion-card>
          <ion-card-content>
            <ion-row v-if="(Object.keys(equivalencias).length !== 0)" class="ion-float-right">
              <ion-col>
                <ion-button fill="outline" class="ion-margin-top" color="medium" @click="goBack()">
                  Cancelar
                </ion-button>
              </ion-col>
              <ion-col>
                <ion-button class="ion-float-right ion-margin-top" @click="enviarEquivalencias">
                  Guardar
                </ion-button>
              </ion-col>
            </ion-row>
            <ion-row v-else class="ion-float-right">
              <ion-col>
                <ion-button fill="outline" class="ion-margin-top" color="medium" @click="goBack()">
                  Volver
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
  <ion-modal id="modal" :is-open="activeModalSelecEquiv" @didDismiss="activeModalSelecEquiv = false"
    :backdropDismiss="false">
    <ion-card style="min-height: 400px;">
      <ion-card-content>
        <ion-label> {{ selectedError.modelShow }} </ion-label>
        <ion-item style="display: flex; align-items: center;">
          <ion-label style="width: 200px;">Datos de Importación:</ion-label>
          <ion-label style="color: #a27b00; display: flex; align-items: center; height: 100%;">{{ selectedError.value
          }}</ion-label>
        </ion-item>
        <ion-item style="display: flex; align-items: center;">
          <ion-label style="width: 200px;">
            Datos Sistema:
          </ion-label>
          <ion-input v-model="textPredictor" placeholder="Ingrese texto"></ion-input>
        </ion-item>
        <ion-content class=" ion-padding" style="min-height: 200px;">
          <ion-list>
            <ion-item v-for=" item  in  filteredData " :key="item._id" @Click="textPredictor = item.nombre">
              {{ item.nombre }}
            </ion-item>
          </ion-list>
        </ion-content>
        <ion-row class=" ion-float-right">
          <ion-col>
            <ion-button fill="outline" class="ion-margin-top" color="medium" @click="activeModalSelecEquiv = false">
              Cancelar
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button class="ion-float-right ion-margin-top" @click="saveEquivalencia">
              Guardar
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </ion-modal>
</template>

<script>
import {
  IonItem, IonLabel, IonCard, IonCardContent, IonCardHeader, IonGrid, IonCol, IonContent, IonList, IonPage, IonRow, IonButton, IonModal, IonInput, alertController, IonIcon,
} from "@ionic/vue";
import http from "@/api/http";
import { Cultivos, Semilleros, VariedadesCultivo, Productos, Enfermedades, Insectos, Malezas, ProductosPlaga, TiposAdversidades } from "@/api";
import modelosEquiv from "@/utils/modelosEquivalencias.js";
import { addOutline, reloadOutline, rainyOutline, trash } from "ionicons/icons";
import dateFormat from "@/utils/dateFormat";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    IonContent, IonLabel, IonItem, IonGrid, IonList, IonButton, IonPage, IonCard, IonCardContent, IonCardHeader, IonRow, IonCol, IonModal, IonInput, IonIcon,
  },
  middleware: ["authenticated", "auth_cliente"],
  data: () => ({

    /* eslint-disable */
    transformModel: {
      //para tenencia,momentos_fertilizacion,momentos_pulverizacion,tiposPlagas,unidades
      'tenencia': { 'origen': 'System', 'clase': 'tenencias', 'modelDB': 'Tenencia' },
      'momento fertilizacion': { 'origen': 'System', 'clase': 'momentos_fertilizacion', 'modelDB': 'Momento Fertilizacion' },
      'momento pulverizacion': { 'origen': 'System', 'clase': 'momentos_pulverizacion', 'modelDB': 'Momento Pulverizacion' },
      'tipo plaga': { 'origen': 'System', 'clase': 'tiposPlagas', 'modelDB': 'Tipo plaga' },
      'unidad medida': { 'origen': 'System', 'clase': 'unidades', 'modelDB': 'Unidad medida' },
      //para Cultivos, Semilleros, VariedadesCultivo, ProductosFertilizacion, Enfermedades, Insectos, Malezas, ProductosPlaga, TiposAdversidades
      'cultivo': { 'origen': 'Database', 'clase': Cultivos, 'modelDB': 'Cultivo' },
      'cultivo antecesor': { 'origen': 'Database', 'clase': Cultivos, 'modelDB': 'Cultivo' },
      'semillero': { 'origen': 'Database', 'clase': Semilleros, 'modelDB': 'Semillero' },
      'variedad cultivo': { 'origen': 'Database', 'clase': VariedadesCultivo, 'modelDB': 'Variedad Cultivo' },
      'producto': { 'origen': 'Database', 'clase': Productos, 'modelDB': 'Producto' },
      'enfermedad': { 'origen': 'Database', 'clase': Enfermedades, 'modelDB': 'Enfermedad' },
      'insecto': { 'origen': 'Database', 'clase': Insectos, 'modelDB': 'Insecto' },
      'maleza': { 'origen': 'Database', 'clase': Malezas, 'modelDB': 'Maleza' },
      'producto plaga': { 'origen': 'Database', 'clase': ProductosPlaga, 'modelDB': 'Producto plaga' },
      'adversidad': { 'origen': 'Database', 'clase': TiposAdversidades, 'modelDB': 'Tipo Adversidad' },
    },
    /* eslint-enable */

    fileName: "",
    selectedOrigen: "",

    equivalencias: [],  //        Arreglo de { seccion, model, modelAdapted, modelShow, modelDB, contenido:[ { value,origenEquivalencia,valueEquivalencia    } ] }
    selectedError: {}, // Objeto de la forma { seccion, model, modelAdapted, modelShow, modelDB,              value, origenEquivalencia, valueEquivalencia, _id   }
    progress: [], //              Arreglo de { seccion, model, modelAdapted, modelShow, modelDB, cantidad, solucionados}

    activeModalSelecEquiv: false,
    file: {},
    fileContent: {},
    fileInput: {},
    xlsType: "application/vnd.ms-excel",
    xlsxType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    icons: {
      add: addOutline,
      reload: reloadOutline,
      rain: rainyOutline,
      trash: trash
    },
    activeCardEquiv: false,
    equivalenciasRealizadas: [],
    dataPredictor: [],
    textPredictor: "",
    rutaAnterior: "",
    idProductor: "",
  }),
  created() {
    const data = this.$store.state.dataPasada.error;
    this.fileName = this.$store.state.dataPasada.archivo;
    this.selectedOrigen = this.$store.state.dataPasada.origen;
    this.rutaAnterior = this.$store.state.dataPasada.rutaAnterior;
    console.log(this.rutaAnterior)
    this.idProductor = this.$store.state.dataPasada.idProductor;
    const errorList = JSON.parse(data.error);
    for (const fila of errorList) {
      if (fila.errores.equivalenceErrors && fila.errores.equivalenceErrors.length > 0) {
        for (const error of fila.errores.equivalenceErrors) {
          if (error) {
            let modelShow = {};
            if (fila.seccion === 'Fertilizacion' && error.model === 'Momento') { modelShow = "Momento Fertilizacion"; }
            else if (fila.seccion === 'Pulverizacion' && error.model === 'Momento') { modelShow = "Momento Pulverizacion"; }
            else if (fila.seccion === 'Pulverizacion' && error.model === 'Producto') { modelShow = "Producto Plaga"; }
            else if (fila.seccion === 'Fertilizacion' && error.model === 'Producto') { modelShow = "Producto"; }
            else { modelShow = error.model; }
            const modelTransformed = this.transformModel[modelShow.toLowerCase()];
            if (this.equivalencias.some(e => e.seccion === fila.seccion && e.model === error.model)) {
              const index = this.equivalencias.findIndex(e => e.seccion === fila.seccion && e.model === error.model);
              if (!(this.equivalencias[index].contenido.some(e => e.value === error.value))) {
                this.equivalencias[index].contenido.push({ value: error.value, origenEquivalencia: modelTransformed.origen });
                const index2 = this.progress.findIndex(e => e.seccion === fila.seccion && e.model === error.model);
                this.progress[index2].cantidad++;
              }
            } else {
              this.equivalencias.push({
                seccion: fila.seccion,
                model: error.model,
                modelAdapted: modelTransformed.clase,
                modelShow: modelShow,
                modelDB: modelTransformed.modelDB,
                contenido: [{ value: error.value, origenEquivalencia: modelTransformed.origen }]
              });
              this.progress.push({ seccion: fila.seccion, model: error.model, modelAdapted: modelTransformed.clase, modelShow: modelShow, modelDB: modelTransformed.modelDB, cantidad: 1, solucionados: 0 });
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState(["dataPasada"]),
    ...mapActions(["mandarData"]),
    filteredData() {
      if (this.textPredictor === "") {
        return this.dataPredictor;
      } else {
        const filtrado = this.dataPredictor.filter((item) =>
          item.nombre.toLowerCase().includes(this.textPredictor.toLowerCase())
        );
        return filtrado;
      }
    },
  },
  methods: {
    downloadErrores() {
      const data = this.$store.state.dataPasada.error;
      const mimeType = "text/javascript";
      const fileName =
        "Errores_Importacion_" +
        dateFormat(new Date(), "dd-MM-yyyy") +
        "_-_" +
        dateFormat(new Date(), "HH-mm") +
        ".txt";
      try {
        fetch(`data:${mimeType};JSON,${this.beautifyErrors(data.error)}`)
          .then(res => res.blob())
          .then(blob => new File([blob], fileName, { type: mimeType }))
          .then(doc => {
            const url = (window.URL || window.webkitURL).createObjectURL(doc);
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.dataset.downloadurl = `${mimeType}:${a.download}:${a.href}`;
            document.body.appendChild(a);
            a.click();
            a.addEventListener('error', (err) => {
              console.error('Error durante la descarga:', err);
            });
            a.addEventListener('load', () => {
              a.remove();
            });
            return url;
          });
      } catch (error) {
        console.error('Error durante la creación del archivo:', error);
      }
    },
    beautifyErrors(errors) {
      const errorList = JSON.parse(errors);
      let beautifiedErrors = "";
      for (const item of errorList) {
        if (item.errores.basicErrors && item.errores.basicErrors.length > 0) {
          beautifiedErrors += `Errores en fila "${item.fila}", seccion "${item.seccion}":\n`;
          for (const error of item.errores.basicErrors) {
            beautifiedErrors += `--> ${error.message}\n`;
          }
          beautifiedErrors += `\n`
        }
      }
      return beautifiedErrors;
    },
    colorEquivalencia(error) {
      if (error.cantidad > error.solucionados) {
        return "warning";
      } else {
        return "success";
      }
    },
    selectModel(error) {
      this.selectedError.seccion = error.seccion;
      this.selectedError.model = error.model;
      this.selectedError.modelAdapted = error.modelAdapted;
      this.selectedError.modelDB = error.modelDB;
      this.selectedError.modelShow = error.modelShow;
      this.activeCardEquiv = true;
      this.$nextTick(() => {
        const cardEquivalencias = document.getElementById('card-equivalencias');
        cardEquivalencias.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    },
    equivalenciasFiltered(seccion, model) {
      return this.equivalencias.filter(e => e.seccion === seccion && e.model === model)[0].contenido;
    },
    activateModalSelecEquiv(error) {
      this.textPredictor = "";
      this.dataPredictor = [];
      this.selectedError.value = error.value;
      this.selectedError.origenEquivalencia = error.origenEquivalencia;
      if (this.selectedError.origenEquivalencia === 'System') {
        this.dataPredictor = modelosEquiv[this.selectedError.modelAdapted].map((item, i) => ({ _id: -1 - i, nombre: item }));
      }
      else if (this.selectedError.origenEquivalencia === 'Database') {
        if (this.selectedError.model === 'Variedad Cultivo') {
          this.selectedError.modelAdapted.getAll().then(res => {
            this.dataPredictor = res.data.map(item => ({ _id: item._id, nombre: item.variedad }));
          });
        } else {
          this.selectedError.modelAdapted.getAll().then(res => {
            this.dataPredictor = res.data.map(item => ({ _id: item._id, nombre: item.nombre }));
          });
        }
      }
      this.activeModalSelecEquiv = true;
    },
    async saveEquivalencia() {
      let equivalenciaAgregar = {};
      if (!(this.dataPredictor.some(item => item.nombre === this.textPredictor))) {
        const alert = await alertController.create({
          header: "Debe hacer click en un elemento de la lista", buttons: [{ text: "Aceptar" }]
        });
        alert.present();
        return;
      }
      if (!(this.equivalencias.some(e => e.seccion === this.selectedError.seccion && e.model === this.selectedError.model && e.contenido.some(e => e.value === this.selectedError.value && e.valueEquivalencia)))) {
        const index = this.progress.findIndex(e => e.seccion === this.selectedError.seccion && e.model === this.selectedError.model);
        this.progress[index].solucionados++;
      }
      for (const elegido of this.dataPredictor) {
        if (elegido.nombre === this.textPredictor) {
          this.selectedError._id = elegido._id;
          break;
        }
      }
      this.selectedError.valueEquivalencia = this.textPredictor;
      const index = this.equivalencias.findIndex(e => e.seccion === this.selectedError.seccion && e.model === this.selectedError.model);
      const index2 = this.equivalencias[index].contenido.findIndex(e => e.value === this.selectedError.value);
      this.equivalencias[index].contenido[index2].valueEquivalencia = this.textPredictor;
      if (this.selectedError._id < 0) {
        equivalenciaAgregar = {
          dataType: 'System',
          model: this.selectedError.modelDB,
          equivalenceValue: this.selectedError.value,   //Es al reves de la logica porque asi lo hicieron en el backend
          value: this.selectedError.valueEquivalencia,  //Es al reves de la logica porque asi lo hicieron en el backend
        }
      } else {
        equivalenciaAgregar = {
          dataType: 'Database',
          model: this.selectedError.modelDB,
          modelId: this.selectedError._id,
          equivalenceValue: this.selectedError.value,   //Es al reves de la logica porque asi lo hicieron en el backend
          value: this.selectedError.valueEquivalencia,  //Es al reves de la logica porque asi lo hicieron en el backend
        }
      }
      this.equivalenciasRealizadas.push(equivalenciaAgregar);
      this.activeModalSelecEquiv = false;
    },
    deleteEquiv(error) {
      const index = this.equivalenciasRealizadas.findIndex(e => e.equivalenceValue === error.value && e.value === error.valueEquivalencia);
      this.equivalenciasRealizadas.splice(index, 1);
      const index2 = this.equivalencias.findIndex(e => e.seccion === this.selectedError.seccion && e.model === this.selectedError.model);
      const index3 = this.equivalencias[index2].contenido.findIndex(e => e.value === error.value);
      this.equivalencias[index2].contenido[index3].valueEquivalencia = "";
      const index4 = this.progress.findIndex(e => e.seccion === this.selectedError.seccion && e.model === this.selectedError.model);
      this.progress[index4].solucionados--;
    },
    async enviarEquivalencias() {
      if (this.equivalenciasRealizadas.length === 0) {
        const alert = await alertController.create({
          header: "No hay ninguna equivalencia realizada", buttons: [{ text: "Aceptar" }]
        });
        alert.present();
        return;
      }
      const postData = {
        originImport: this.selectedOrigen._id,
        equivalences: this.equivalenciasRealizadas,
      }
      try {
        await http.post('/api/save-equivalencias', postData);
        const alert = await alertController.create({
          header: "Equivalencias Guardadas",
          message: "Se han guardado las equivalencias con exito",
          buttons: [{ text: "Aceptar" }]
        });
        this.goBack()
        return alert.present();
      } catch (error) {
        const alert = await alertController.create({
          header: "Error al guardar las equivalencias",
          message: error.error || "Error al guardar las equivalencias",
          buttons: [{ text: "Aceptar" }]
        });
        return alert.present();
      }
    },
    goBack() {
      this.$router.push(this.rutaAnterior);
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
}

.hovered:hover {
  background-color: #2dd36f25;
}

ion-card {
  margin-top: 16px;
}
</style>
