export default {
    tenencias: ["Propio", "Arrendado", "Asesorado"],
    momentos_adversidad: [
      "Emergencia",
      "Vegetativo Temprano",
      "Vegetativo Tardío",
      "Reproductivo Temprano",
      "Reproductivo Tardío",
      "Madurez Fisiológica"
    ],
    momentos_fertilizacion: [
      "Presiembra",
      "Siembra",
      "Vegetativo temprano",
      "Vegetativo tardío",
      "Reproductivo temprano",
      "Reproductivo tardío"
    ],
    momentos_pulverizacion: [
      "Barbecho largo",
      "Barbecho corto",
      "Presiembra",
      "Siembra",
      "Preemergencia",
      "Vegetativo temprano",
      "Vegetativo tardío",
      "Reproductivo temprano",
      "Reproductivo tardío",
      "Desecante cosecha"
    ],
    tiposPlagas: ["Enfermedad", "Insecto", "Maleza"],
    unidades: ["litros/ha", "cm3/ha", "gr/ha", "kg/ha"],
};